
.image{

    height: 20rem;
    width: auto;
    
}
.h1{
    font-size: 3rem;
    margin-bottom: 0.5 rem;
    color: black;
}



.contactform{
    /* margin: 1rem; */
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
   
     /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    margin-left: 40px;
    
    
}
.aa{
    margin-left: 1rem;
}
.name,.email,.msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40,40,40);
}
.submit1 {
    border: 1px solid black;
    background-color:darkslateblue;
    font: bold;
    margin: 0.5rem;
    border-radius: 0.5rem;
    padding: 0.75rem 2.5rem;
    color: aliceblue;
    margin-right: 15rem;
}


.get{
    width: 100%;
    height: 34rem;
    display: flex;
    background-size:auto;
    
    justify-content: center;
}
.content{
    display:flex;
    /* justify-content: center;
    align-items: center; */

}
.cont{
    width: 25%;
    display: flex;
    flex-direction: column;
   
    height: auto;
    background-color: black;
    color: white;
}
.form{
    width: 75%;
    height: auto;
    display: flex;
}
.btn1{
    background-color: black;
    text-decoration: none;
    color: white;
     /* border-radius: 3rem;  */
    margin-bottom: 2rem;
    height: 3rem;
    border: none;
 
}

.btn1:hover{
    background-color:white;
    color: black;
    
    text-decoration: none;
  
    height: 3rem;

    
}

.btn1:hover{
    background-color: black;
    text-decoration: none;
    color: white;
    height:auto ;
     border-radius: 2rem; 
    border: 1px solid grey; 
    /* margin: 1rem 1rem 1rem 1rem; */
    
}
.h33{
    color: white;
    font-size: 1.5rem;
    font-weight:500 ;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* overflow: hidden;
    position: relative; */
    margin: 1rem 1rem 3rem 1rem;

}
.h44{
    color:white;
    font-size: 1rem;
    /* font-weight:500 ; */
    font: bold;
    font-family: Arial, Helvetica, sans-serif;
    /* overflow: hidden;
    position: relative; */

}
.contact1{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 3rem 1rem 3rem 1rem;
}
#contactUs{

    width: 100%;
    display: flex; 
    height: auto;   
    justify-content:flex-start;
    align-items:baseline;  
    position: absolute;

}
#Contact{
    background-color: white;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
     /* justify-content: center;  */
}
.name1{
    width: 100%;
    margin: 1px;
    display: flex;
    flex-direction: column;
    align-items:baseline;
}