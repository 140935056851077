.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
}

.logo {
  height: 50px;
}

.desktopmenu {
  display: flex;
  align-items: center;
}

.desktopMenuListItems {
  margin: 0 15px;
  cursor: pointer;
  position: relative;
}

/* Mega Menu Container */
.mega-menu-container {
  position: relative;
}

/* Mega Menu Styling */
.mega-menu {
  background-image: url('../../data/group-266.webp');
  position: absolute;
  top: 100%;
  left: 50%; /* Center horizontally relative to the container */
  transform: translateX(-50%); /* Offset by 50% of its own width */
  
  border:2px solid grey;
 
  width: 1200px; /* Adjust as needed */
  display: flex;
  background-color: white;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
}

/* Mega Menu Columns */
.mega-menu-column {
  flex: 1;
  padding: 0 20px;
}

.mega-menu-column h3 {
  margin-bottom: 15px;
  font-size: 16px;
}

.mega-menu-item {
  display: block;
  margin: 10px 0;
  padding: 10px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.mega-menu-item:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
}

/* Adjustments to prevent flickering when hovering */
.mega-menu-container:hover .mega-menu {
  display: flex;
}
