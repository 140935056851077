#intro {
    height: calc(100vh - 4rem);
    width: 100%;
    max-width: 100vw;
    height: 40rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.app {
    width: 100%;
    background-image: url('../../data/group-266.webp');
    background-size: cover;
    background-position: center;
    position: relative;
}

.getintouch {
    height: 3rem;
    width: 10rem;
    border: 2px solid black;
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 3rem;
    margin-left: 1rem;
}

.getintouch:hover {
    border: 2px solid white;
    cursor: grabbing;
}

.link {
    margin: 0.5rem;
    width: 30px;
    height: auto;
}

.stepimg {  
    height: 50vh;
    width: 20vw;
    position: relative;
}

.introContent {
    height: 100vh;
    width: 100vw;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bg {
    position: absolute;
    z-index: -1;
    right: 5rem;
    top: 5rem;
    object-fit: cover;
    height: 100vh;
}

.glb1 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    height: 6vh;
    width: 40%;
}

.services {
    display: flex;   
    align-items: center; 
    justify-content: center;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
}
.slogo1 {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    /* justify-content: center; */
}

.slogo {
    width: 3rem;
    height: auto;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

.service1 {
    width: 75%;
    /* padding: 1px 5px; */
    display: flex;
    justify-content: space-between;
}

.service2 {
    display: flexbox;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: auto;
    position: relative;
    transition: box-shadow 0.3s ease-in-out, z-index 0.3s ease-in-out; /* Smooth transition for box-shadow and z-index */
    position: relative; /* Ensures z-index takes effect */
    z-index: 1; /* Default z-index */
}

.service2:hover {
    background-color: rgb(255, 255, 254);
    border-radius: 2rem;
    border-style: ridge;
    padding: 1rem;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); /* Glowing effect */
    z-index: 10; /* Brings the hovered item to the front */
}
.carousel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

.hero {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    margin-top: 5rem;
   
}
.hero1 {
    display:flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
     
}

.h111 {
    color: black;
    font-size: 3.5rem;
    font-weight: 700;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.h222 {
    color: black;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
}

.h3 {    
    color: black;
    font-size: 1.5rem;
    font-weight: 500;
}.h333 {   
    width: 30rem; 
    color: black;
    font-size: 1.5rem;
    font-weight: 500;
}


.h4 {
    color: black;
    font-size: 1rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration:wavy;
}

.introtext {
    font-size: 1rem;
    font-weight: 300;
    line-height: normal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.about {
    margin: 0 5rem;
}

.button {
    height: 2rem;
    width: 8rem;
    color: white;
    text-align: center;
    background-color: cornflowerblue;
    border: 2px solid yellow;
}

.image {
    align-content: center;
    display: flex;
    flex-direction: column;
    object-fit: contain;
    padding: 3rem;
    width: 10vw;
    height: 10vh;
}

.flow {
    background-image: url('../../data/group-266.webp');
    background-size: cover;
    background-position: center;
    z-index: -1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.subflow {
    background-image: url('../../data/cta-bg.webp');
    background-size: cover;
    width: 10rem;
    height: 10rem;
    display: flex;
    margin: 1rem 3rem;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.talent {
    width: 100%;
    max-width: 70%;
    display: flex;
    justify-content: center;
    /* margin-left: 15rem;  */
    margin-top: 5rem;
}

.talentimg {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 60%;
    height: auto;
}

.talenttext {
    width: 60%;
    justify-content: center;
    align-items: center; 
}

.fstep {
    background-image: url('../../data/group-266.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.imgs {
    height: 40%;
    width: 55%;
}

.imgs1 {
    width: 100%;
}

.work {
    margin-top: 2rem;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 840px) {
    .bg {
        right: auto;
    }

    .h111 {
        font-size: 2.5rem;
    }

    .h222 {
        font-size: 2rem;
    }

    .h3 {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 480px) {
    .bg {
        right: auto;
    }

    .h111 {
        font-size: 2rem;
    }

    .h222 {
        font-size: 1.5rem;
    }

    .h3 {
        font-size: 1rem;
    }
}

.footer {
    width: 100%; 
    height: 1.5rem;
    background: rgb(120, 120, 120);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
}

.footermenu {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    justify-content: center;
    align-content: center;  
}

.list {
    width: 35%;
    padding: 2rem;
    display: table-column;
    justify-content: center;
}

.app1 {
    width: 100%;
    background-image: url('../../data/cta-bg.webp');
    background-size: cover;
    background-position: center;
    position: relative;
}
